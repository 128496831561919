import React from 'react'
import ringmetricsLogo from '../images/ringmetricslogo.svg'
const BottomSec = () => {
    return (
        <>
            <div className='bottom-main'>
                <div className='bottom-bg' />
                <div className='bottom-context'>
                    <h2>
                        Ready to Transform Your Calls into Results?
                    </h2>
                    <p>
                        Join the Ringmetrics revolution now.
                    </p>
                    <a href="mailto: info@ringmetrics.ai" style={{ textDecoration: 'none', color: '#0A5DFE' }}>
                        <button>
                            Get Your Custom Quote
                        </button>
                    </a>
                </div>

            </div>
        </>
    )
}

export default BottomSec
