import React from 'react'
import ringmetricsLogo from '../images/ringmetricslogo.svg'

const Footer = () => {
    return (
        <>
            <div className='bottom-bar-main'>
                <div className='bottom-bar'>
                    <div>
                        <a href="/"><img src={ringmetricsLogo} alt="" /></a>
                    </div>
                    <div className='bottom-bar-address'>
                        <div className='bar-name'>
                            <p>INNOTECH <br />CONSULTANTS <br />LIMITED</p>
                        </div>
                        <div className='bar-number'>
                            <p> Company Number:</p>
                            <p> 14606904</p>
                        </div>
                        <div className='bar-adress'>
                            <p>Address: </p>
                            <a href="https://maps.app.goo.gl/aUVH9TWjQ9CiTpG29" target="_blank"><p>150 Park Farm Drive, Allestree,<br /> Derby, England, DE22 2QP</p></a>
                        </div>
                    </div>
                </div>
                <div className='copyright'>
                    <div>
                        <a href="/Terms&Conditions" style={{ color: '#fff' }}><p>Terms & Condition</p></a>
                    </div>
                    <div>
                        <a href="/Privacy-Policy" style={{ color: '#fff' }}><p>Privacy Policy</p></a>
                    </div>
                    <div>
                        <p>
                            Copyright @ 2024. All rights reserved
                        </p>
                    </div>


                </div>
            </div>
            <div className='bottom-bar-mob'>
                <div className='bottom-bar-address'>
                    <div className='bar-name'>
                        <p>INNOTECH <br />CONSULTANTS <br />LIMITED</p>
                    </div>
                    <div className='bar-number'>
                        <p> Company Number:</p>
                        <p> 14606904</p>
                    </div>
                    <div className='bar-adress'>
                        <p>Address: </p>
                        <a href="https://maps.app.goo.gl/aUVH9TWjQ9CiTpG29" target="_blank"><p>150 Park Farm Drive, Allestree,<br /> Derby, England, DE22 2QP</p></a>
                    </div>
                </div>
                <div>
                    <a href="/"><img src={ringmetricsLogo} alt="" /></a>
                </div>
                <div className='copyright-mob'>
                    <div>
                        <a href="/Terms&Conditions" style={{ color: '#fff' }}><p>Terms & Condition</p></a>
                    </div>
                    <div>
                        <a href="/Privacy-Policy" style={{ color: '#fff' }}><p>Privacy Policy</p></a>
                    </div>
                    <div>
                        <p>
                            Copyright @ 2024. All rights reserved
                        </p>
                    </div>

                </div>

            </div>
        </>
    )
}

export default Footer
