import React from 'react'
import graph from '../images/graph.png'
const GraphSection = () => {
    return (
        <>
            <div className='graphSection-main' id='graph'>
                <div className='graphSection-left'>
                    <div className='graphSection-left-context'>
                        <h2>
                            Transforming Call Challenges into Business Opportunities.
                        </h2>
                        <p>
                            Our software tracks every possible detail about your users allowing you to optimize for profit in a previously unobtainable way.
                        </p>
                    </div>
                    {/* <button>
                        Schedule a Live Demo
                    </button> */}
                </div>
                <div className='graphSection-right'>
                    <img src={graph} alt='graph' />
                </div>
            </div>
        </>
    )
}

export default GraphSection
