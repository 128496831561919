import React from 'react'
import Animation from './Animation.tsx'
import campaignT from '../images/campaignT.svg'
import automatedR from '../images/automatedR.svg'
import numberM from '../images/numberM.svg'
import callA from '../images/callA.svg'
import loadB from '../images/loadB.svg'
import liveS from '../images/liveS.svg'

const SeamLess = () => {
    return (
        <>
            <div className='seamless-lead-main' id='lead-track'>
                <div className='seamless-lead-context'>
                    <h2>
                        Seamless Lead Tracking with Ringmetrics
                    </h2>
                    <p>
                        Discover the full potential of every call with Ringmetrics's suite of features designed for smarter insights,<br /> seamless integrations, and enhanced customer connections.
                    </p>
                </div>
                <div className='seamless-lead-content'>
                    <div className='seamless-content-left'>
                        <div className='seamless-card'>
                            <div className='seamless-card-upper'>
                                <img src={campaignT} alt='' />
                                <p>01</p>
                            </div>
                            <div className='seamless-card-lower'>
                                <h3>Campaign Tracking</h3>
                                <p>Track and monitor your call campaigns with real-time precision.</p>
                            </div>
                        </div>
                        <div className='seamless-card'>
                            <div className='seamless-card-upper'>
                                <img src={automatedR} alt='' />
                                <p>02</p>
                            </div>
                            <div className='seamless-card-lower'>
                                <h3>Automated Routing</h3>
                                <p>Create dynamic routing plans to automatically manage your call flow.</p>
                            </div>
                        </div>
                        <div className='seamless-card'>
                            <div className='seamless-card-upper'>
                                <img src={numberM} alt='' />
                                <p>03</p>
                            </div>
                            <div className='seamless-card-lower'>
                                <h3>Number Management</h3>
                                <p>Allocate on-demand toll free and local phone numbers all over the world.</p>
                            </div>
                        </div>
                    </div>
                    <div className='seamless-animation'>
                        <Animation />
                    </div>
                    <div className='seamless-content-right'>
                        <div className='seamless-card'>
                            <div className='seamless-card-upper'>
                                <p>04</p>
                                <img src={callA} alt='' />
                            </div>
                            <div className='seamless-card-lower'>
                                <h3>Call Attribution</h3>
                                <p>Attribute traffic sources, keywords, and other data to your calls.</p>
                            </div>
                        </div>
                        <div className='seamless-card'>
                            <div className='seamless-card-upper'>
                                <p>05</p>
                                <img src={loadB} alt='' />
                            </div>
                            <div className='seamless-card-lower'>
                                <h3>Load Balancing</h3>
                                <p>Eliminate abandons, reclaim lost revenue, and sell your best calls.</p>
                            </div>
                        </div>
                        <div className='seamless-card'>
                            <div className='seamless-card-upper'>
                                <p>06 </p>
                                <img src={liveS} alt='' />
                            </div>
                            <div className='seamless-card-lower'>
                                <h3>Live Support</h3>
                                <p>Get live support from industry experts with all the resources needed to help.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default SeamLess
