import React, { useState } from 'react'

import PlanCard from './PlanCard.tsx';

import standard from '../images/standard.png'
import business from '../images/business.png'
import enterprise from '../images/enterprise.png'

const Plans = () => {
    const [checked, setChecked] = useState(false);
    return (
        <>
            <div className='plans-main' id='pricing'>
                <div className='plans-context'>
                    <div className='plans-context-text'>
                        <h2>
                            Flexible Plans for Every Business Journey!
                        </h2>
                        <p>
                            Find your perfect fit with scalable plans. Maximize value at every stage of your growth.
                        </p>
                    </div>
                    <div className='plans-context-button'>
                        <p>
                            Monthly
                        </p>
                        <div>
                            <div className="form-check form-switch">
                                <input className="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckDefault"
                                    onChange={(e) => setChecked(e.target.checked)}
                                    checked={checked} />
                            </div>
                        </div>
                        <p>
                            Yearly
                        </p>
                    </div>
                </div>

                {checked ? (
                    <div className='plansCard-main'>
                        <PlanCard
                            index={1}
                            imgSrc={standard}
                            packageName='Standard'
                            para='Unleash the Power of Your Business with Pro Plan.'
                            price='$40'
                            per='per month'
                            saving='Save upto 20%'
                            bulletHeading={[
                                'Recordings',
                                'Local Minutes',
                                'Toll Free Minutes',
                                'Local Numbers',
                                'Toll Free Numbers',
                                'Call Rejection Cost'
                            ]}
                            rates={[
                                '$0.008/Minute',
                                '$0.05/Minute',
                                '$0.055/Minute',
                                '$2.5/Month',
                                '$3/Month',
                                '$0.005/Call'
                            ]}
                            info={[
                                "*All prices include adding the buyer to the call if the buyer's number is toll-free or SIP. For local numbers, add $0.005 per minute."
                            ]}
                        />
                        <PlanCard
                            index={2}
                            imgSrc={business}
                            packageName='Business'
                            para='Take Your Business to the Next Level with Business Plan.'
                            price='$80'
                            per='per month'
                            saving='Save upto 20%'
                            bulletHeading={[
                                'Recordings',
                                'Local Minutes',
                                'Toll Free Minutes',
                                'Local Numbers',
                                'Toll Free Numbers',
                                'Call Rejection Cost'
                            ]}
                            rates={[
                                '$0.004/Minute',
                                '$0.04/Minute',
                                '$0.045/Minute',
                                '$1.5/Month',
                                '$2/Month',
                                '$0.005/Call'
                            ]}
                            info={[
                                "*All prices include adding the buyer to the call if the buyer's number is toll-free or SIP. For local numbers, add $0.005 per minute."
                            ]}
                        />
                        <PlanCard
                            index={3}
                            imgSrc={enterprise}
                            packageName='Enterprise'
                            para='Unleash the Power of Your Business with Enterprise Plan.'
                            price='Custom'
                            per='per year'
                            // saving='Save $650'
                            bulletHeading={[
                                'Recordings',
                                'Local Minutes',
                                'Toll Free Minutes',
                                'Local Numbers',
                                'Toll Free Numbers',
                            ]}
                            rates={[
                                'Custom',
                                'Custom',
                                'Custom',
                                'Custom',
                                'Custom',
                            ]}
                        />
                    </div>
                ) : (
                    <div className='plansCard-main'>
                        <PlanCard
                            index={1}
                            imgSrc={standard}
                            packageName='Standard'
                            para='Unleash the Power of Your Business with Pro Plan.'
                            price='$50'
                            per='per month'
                            // saving='Save 10%'
                            bulletHeading={[
                                'Recordings',
                                'Local Minutes*',
                                'Toll Free Minutes*',
                                'Local Numbers',
                                'Toll Free Numbers',
                                'Call Rejection Cost'
                            ]}
                            rates={[
                                '$0.008/Minute',
                                '$0.05/Minute',
                                '$0.055/Minute',
                                '$2.5/Month',
                                '$3/Month',
                                '$0.005/Call'
                            ]}
                            info={[
                                "*All prices include adding the buyer to the call if the buyer's number is toll-free or SIP. For local numbers, add $0.005 per minute."
                            ]}
                        />
                        <PlanCard
                            index={2}
                            imgSrc={business}
                            packageName='Business'
                            para='Take Your Business to the Next Level with Business Plan.'
                            price='$100'
                            per='per month'
                            // saving='Save 10%'
                            bulletHeading={[
                                'Recordings',
                                'Local Minutes*',
                                'Toll Free Minutes*',
                                'Local Numbers',
                                'Toll Free Numbers',
                                'Call Rejection Cost'
                            ]}
                            rates={[
                                '$0.004/Minute',
                                '$0.04/Minute',
                                '$0.045/Minute',
                                '$1.5/Month',
                                '$2/Month',
                                '$0.005/Call'
                            ]}
                            info={[
                                "*All prices include adding the buyer to the call if the buyer's number is toll-free or SIP. For local numbers, add $0.005 per minute."
                            ]}
                        />
                        <PlanCard
                            index={3}
                            imgSrc={enterprise}
                            packageName='Enterprise'
                            para='Unleash the Power of Your Business with Enterprise Plan.'
                            price='Custom'
                            per='per month'
                            bulletHeading={[
                                'Recordings',
                                'Local Minutes',
                                'Toll Free Minutes',
                                'Local Numbers',
                                'Toll Free Numbers',
                            ]}
                            rates={[
                                'Custom',
                                'Custom',
                                'Custom',
                                'Custom',
                                'Custom',
                            ]}

                        />
                    </div>
                )}
            </div>
        </>
    )
}

export default Plans
