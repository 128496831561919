import React from 'react'
import CallTracking from './CallTracking.tsx'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from './Components/Navbar.tsx';
import Terms from './Components/Terms.tsx';
import Privacy from './Components/Privacy.tsx';
import Footer from './Components/Footer.tsx';
const App = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path='/' element={<CallTracking />} />
        <Route path='/Terms&Conditions' element={<Terms />} />
        <Route path='/Privacy-Policy' element={<Privacy />} />
      </Routes>
      <Footer />
    </>
  )
}

export default App
