import React from 'react'
import './CallTracking.css'
import TrackingHero from './Components/TrackingHero.tsx'
import GraphSection from './Components/GraphSection.tsx'
import FrameWorkSection from './Components/FrameWorkSection.tsx'
import Reporting from './Components/Reporting.tsx'
import SeamLess from './Components/SeamLess.tsx'
import Plans from './Components/Plans.tsx'
import BottomSec from './Components/BottomSec.tsx'

const CallTracking = () => {
    return (
        <>
            <div className='callTracking'>
                <TrackingHero />

                <GraphSection />

                <FrameWorkSection />

                <Reporting />

                <SeamLess />

                <Plans />

                <BottomSec />
            </div>
        </>
    )
}

export default CallTracking
