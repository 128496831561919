import React from 'react'

const FrameWorkSection = () => {
    return (
        <>
            <div className='framwork-main' id='framework'>
                <div className='framework-gradient' />
                <div className='framework-context'>
                    <h2>Built-In Pay Per Call Framework.</h2>
                    <p>Unlock unlimited revenue potential.</p>
                </div>
                <div className='framework-content'>
                    <div className='content-left'>
                        <div className='stroke' />
                        <p>Expand Your Reach with Ringmetrics</p>
                    </div>
                    <div className='circle-main'>
                        <div className='content-circle-first'>
                            <h3>
                                Buy & Sell Phone Calls
                            </h3>
                            <p>
                                Build your Pay Per Call marketing business with tools made for success.
                            </p>
                        </div>
                        <div className='content-circle-second'>
                            <h3>
                                Compliance Monitoring
                            </h3>
                            <p>
                                Protect your business with passive real-time compliance monitoring.
                            </p>
                        </div>
                        <div className='content-circle-third'>
                            <h3>
                                State of Art Reporting Monitoring
                            </h3>
                            <p>
                                Crush your competition with the industries best reporting tool, Ringmetrics.
                            </p>
                        </div>
                    </div>
                    <div className='content-right'>
                        <div className='stroke' />
                        <p>Optimal Reporting with Ringmetrics</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FrameWorkSection
